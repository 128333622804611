import quickFetch from 'quick-fetch';
const FrontendPreferencesEndpoints = {
  HUBS_SETTING: '/hubs-settings/v2/hubs-setting/fetch',
  USER_ATTRIBUTES: '/user-attributes/v1/app-preferences/user-attributes/fetch',
  HUB_USER_ATTRIBUTES: '/user-attributes/v1/app-preferences/fetch'
};
function earlyRequestPreferencesBatch(preferenceNames, earlyRequestKey, backendEndpoint) {
  quickFetch.makeEarlyRequest(earlyRequestKey, {
    url: quickFetch.getApiUrl(backendEndpoint, true),
    dataType: 'json',
    contentType: 'application/json',
    type: 'POST',
    data: JSON.stringify({
      names: preferenceNames
    })
  });
}
export function earlyRequestUserAttributes(preferenceNames, earlyRequestKey) {
  return earlyRequestPreferencesBatch(preferenceNames, earlyRequestKey, FrontendPreferencesEndpoints.USER_ATTRIBUTES);
}
export function earlyRequestHubsSettings(preferenceNames, earlyRequestKey) {
  return earlyRequestPreferencesBatch(preferenceNames, earlyRequestKey, FrontendPreferencesEndpoints.HUBS_SETTING);
}
export function earlyRequestHubUserAttributes(preferenceNames, earlyRequestKey) {
  return earlyRequestPreferencesBatch(preferenceNames, earlyRequestKey, FrontendPreferencesEndpoints.HUB_USER_ATTRIBUTES);
}